import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function Gallery() {

	const [photoIndex, setPhotoIndex] = useState(0);
	const images = [
		'images/441069962_10228020154989974_2000627672645591973_n.jpg',
		'images/362662534_10229244524445209_4654341384739792214_n.jpg',
		'images/329607197_1706077889835446_7369728890953035316_n.jpg',
		'images/330033364_605342220969414_3075575166396511292_n.jpg',
		'images/kats-pic1.jpg',
		'images/330327568_228309319598455_6173734559676095964_n.jpg',
		'images/336507278_603439028021762_245811235620505094_n.jpg',
		'images/344863507_191076330432592_7024539320234724789_n.jpg',
		'images/347388349_1653069021784265_4635802116707292548_n.jpg',
		'images/352375120_10162110210492985_3705226554104497734_n.jpg',
		'images/12670544_1726305920984397_2648845460273988076_n.jpeg',
		'images/13124829_10154799808129307_8820815988182396041_n.jpeg',
		'images/13177269_1740023909612598_2648302214823045478_n.jpeg',
		'images/13903368_1781951652086490_9008965458997486761_n.jpeg',
		'images/14117725_1789925457955776_8373644098832852385_n.jpeg',
		'images/15698311_1205741689516262_2749329632664377879_n.jpeg',
		'images/15727048_1853341934947461_2612543061397778653_n.jpeg',
		'images/15871520_1427352873971526_5494202960963469367_n.jpeg',
		'images/15894486_1857286661219655_6172717987291548478_n.jpeg',
		'images/16142291_10153985196846486_8517986128628779061_n.jpeg',
		'images/19990131_465686460466257_3006093765326786938_n_d400.jpg',
		'images/Footpath.jpg',
		'images/IMG_1949_d1450.jpg',
	];
	const titles = [
		'Sunset Trails',
		'Kirste 5',
		'Grizzly Finish Line',
		'Seaton, Devon (Grizzly)',
		'Spooky Skies',
		'Wet Shoes Selfie',
		'Hadleigh Legacy 10k',
		'Muddy Shoes Selfie',
		'Rayleigh 10k',
		'Hockley Trails',
		'Gusted Hall Trail',
		'View of Hullbridge and the River Crouch',
		'Codgers Crossing',
		'River Crouch Trail',
		'Hadleigh Castle',
		'On the trail from Hockley to Hullbridge',
		'Gusted Hall to Hockley Woods trail',
		'Roach Valley Way Leg 3',
		'River Crouch looking from Hullbridge to South Woodham Ferrers Parkrun Start',
		'Gusted Hall',
		'Couch to 5k completed for another year',
		'Roach Valley Way Leg 2',
		'Hadleigh Castle'
	];
	const captions = [
		'Nothing beats a Sunset near the end of the Wednesday night run',
		'The Annual Kirste 5 Miler',
		'What it feels like after 20 miles of hell',
		'',
		'',
		'The tradition of finding the deepest puddle to take a shoe selfie',
		'',

	]
	return (
		<div className="gallery">
			<Lightbox
				mainSrc={images[photoIndex]}
				nextSrc={images[(photoIndex + 1) % images.length]}
				prevSrc={images[(photoIndex + images.length - 1) % images.length]}
				onCloseRequest={() => window.location.href = '/'}
				onMovePrevRequest={() => {
					photoIndex > 1 ?
						setPhotoIndex(photoIndex - 1)
						: setPhotoIndex(images.length - 1)
				}
				}
				onMoveNextRequest={() => {
					photoIndex >= images.length - 1 ?
						setPhotoIndex(0)
						: setPhotoIndex(photoIndex + 1)
				}
				}
				imageTitle={titles[photoIndex]}
				imageCaption={captions[photoIndex]}
			/>
		</div>
	)
};

export default Gallery;


