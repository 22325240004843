import React from 'react';
import { FaCalendarAlt } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { TbWorldWww } from "react-icons/tb";

function Events() {
    return (
        <div className="clubPage">
            <div className="pagebody">
                <div className="mainHead">Upcoming Events</div>
                <div className="pageText">Below are some of the upcoming local events attended by HTR, or if you are a member then you can view who's attending on our <a href="https://www.facebook.com/groups/HockleyTrailRunners/events" target="_new">Facebook Events Page.</a></div>
                <div className="twoColText">
                    <div className="twoColPageText">
                        <div className="greenHead">June 2024</div>
                        <div className="pageText"><span className="eventHead">The Rayleigh 10k</span><br /><FaCalendarAlt /> - 2nd June 
                            <br /><CiLocationOn /> - Gusted Hall Lane, Hockley, SS5 4JL<br/><TbWorldWww /> - <a href="https://www.rayleigh10k.co.uk/e/rayleigh-10k-4138" target="_new">https://www.rayleigh10k.co.uk/e/rayleigh-10k-4138</a></div>

                        <div className="pageText"><span className="eventHead">Southend Half Marathon</span><br /><FaCalendarAlt /> - 9th June 
                            <br /><CiLocationOn /> - East Beach, Shoeburyness, SS3 9AD<br/><TbWorldWww /> - <a href="https://www.havenshospices.org.uk/support-us/events/southend-half-marathon-2024" target="_new">https://www.havenshospices.org.uk/support-us/events/southend-half-marathon-2024</a></div>
                            <div className="pageText"><span className="eventHead">Dirty Rotten Scramble</span><br /><FaCalendarAlt /> - 15th June 
                            <br /><CiLocationOn /> - Hole Park Estate, Rolvenden, Cranbrook, TN17 4JA<br/><TbWorldWww /> - <a href="https://scrambleevents.co.uk/" target="_new">https://scrambleevents.co.uk/</a></div>

                        <div className="greenHead">July 2024</div>
                        <div className="pageText"><span className="eventHead">HARP 24</span><br /><FaCalendarAlt /> - Saturday 6th July - Sunday 7th July
                            <br /><CiLocationOn /> - Cherry Orchard Country Park, Southend-on-Sea, Essex.<br /><TbWorldWww /> - <a href="https://action.harpsouthend.org.uk/harp24/sign-up/" target="_new">https://action.harpsouthend.org.uk/harp24/sign-up/</a></div>
                        <div className="pageText"><span className="eventHead">The Kirste 5 Memorial Race</span><br /><FaCalendarAlt /> - 21st July 
                            <br /><CiLocationOn /> -  Grove Woods Playing Fields, Katherine Close, Rayleigh, Essex.<br /><TbWorldWww /> - <a href="https://thekirste5memorialrace.org/" target="_new">https://thekirste5memorialrace.org/</a></div>

                    </div>
                    <div className="twoColPageText">
                        <div className="greenHead">September 2024</div>
                        <div className="pageText"><span className="eventHead">The Black Bull 15</span><br /><FaCalendarAlt /> - 29th Sep 
                            <br /><CiLocationOn /> -  The Black Bull Pub, 244 Rainsford Road, Chelmsford, Essex. CM1 2PN<br /><TbWorldWww /> - <a href="https://www.entrycentral.com/1119" target="_new">https://www.entrycentral.com/1119</a></div>

                        <div className="greenHead">October 2024</div>
                        <div className="pageText"><span className="eventHead">Southend 10k</span><br /><FaCalendarAlt /> - 6th October 
                            <br /><CiLocationOn /> - Southchurch Park East, Lifstan Way, Southend on Sea, Essex SS1 2XD<br /><TbWorldWww /> - <a href="https://www.southend10k.com/" target="_new">https://https://www.southend10k.com/</a></div>

                        <div className="pageText"><span className="eventHead">Chelmsford Marathon & Half Marathon</span><br /><FaCalendarAlt /> - 13th October 
                            <br /><CiLocationOn /> - Central Park, Chelmsford, Essex, CM2 0FQ.<br /><TbWorldWww /> - <a href="https://www.nice-work.org.uk/e/chelmsford-marathon-and-half-marathon-9483" target="_new">https://www.nice-work.org.uk/e/chelmsford-marathon-and-half-marathon-9483</a></div>

                            <div className="pageText"> &nbsp;</div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Events;


