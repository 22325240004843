import React from 'react';
import Slide from 'react-reveal/Slide';

function Beginners() {
    return (
        <div className="clubPage">
            <Slide right>
                <div className="pagebody">
                    <div className="mainHead"><span>Beginners Course</span></div>
                    <div className="pageText">
                        <img src="images/19990131_465686460466257_3006093765326786938_n_d400.jpg" alt="Beginners 1" />
                        <img src="images/IMG_2154_d400.jpg" alt="Beginners 2" />
                    </div>
                    <div className="greenHead">Our 2024 course</div>
                
                    <div className="pageText">Thinking of trying running or perhaps returning after some time out? Our 10 week beginners course will help you progress from zero to 5km (3.1 miles) in a safe and supportive environment. If you already run but would like some coaching to progress, this course is also suitable for you.</div>
                    <div className="pageText"><div className="greenBtn" onClick={() => window.open('https://www.sientries.co.uk/event.php?event_id=13407')}>Sign Up Now</div></div>
                    <div className="greenHead">What you will get:</div>
                    <div className="twoColText">
                        <div className="twoColPageText">
                            <ul>
                                <li>UK athletics qualified leaders and coaches.</li>
                                <li>Gradual safe progression with proven results</li>
                                <li>Coaching topic each week to teach core running skills</li>
                                <li>Dedicated facebook group with support from all the coaches and leaders on the course</li>
                                <li>Run on local trails rather than road</li>
                                <li>Discover the countryside that you live in</li>
                                <li>Graduation 5km run with social event after</li>
                                <li>Supported 5km parkrun</li>
                                <li>Lots of encouragement and support</li>
                                <li>A big smile on your face</li>
                                <li>cost: £22 for the 10 week course payable online. There are 40 places and payment secures your place.</li>
                            </ul>
                        </div>
                        <div className="twoColPageText"><img src="images/beginners2024.jpg" alt="Beginners course" width="400px" height="500px" /></div>
                    </div>
                    <div className="pageText"><div className="greenBtn" onClick={() => window.open('http://www.hockleytrailrunners.co.uk/contact')}>Contact us</div></div>
                    <div className="pageText"><div className="greenBtn" onClick={() => window.open('https://www.facebook.com/hockleytrailrunners')}>Facebook page</div></div>
                    <div className="greenHead">After the course</div>
                    <ul>
                        <li>Join the main hockley trail running club with a dedicated group for running 3 miles or additional groups to progress further</li>
                        <li>Support from leaders and coaches you'll know</li>
                        <li>Join like minded runners that also enjoy running trails</li>
                    </ul>
                    <div className="pageText"></div>
                </div>
            </Slide>
        </div>
    );
}

export default Beginners;


