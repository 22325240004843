import React from 'react';

function HomepageBody() {
    return (
        <div className="homePage">
            <div className="homeBody">
                <div id="homeLogo"></div>
                <div className="homeHead">Welcome to Hockley Trail Runners</div>
                <div className="pageText"><div className="greenBtn" onClick={() => window.open('https://www.sientries.co.uk/event.php?elid=Y&event_id=13406')}>REGISTER HERE FOR 2024-2025</div></div>
                <div className="pageText"><div className="greenBtn" onClick={() => window.open('https://www.facebook.com/groups/HockleyTrailRunners/')}>FACEBOOK GROUP</div></div>
                <div className="pageText"><div className="greenBtn" onClick={() => window.open('/beginners')}>Couch 2 5K Beginners Course</div></div>
                {/* <div className="homeBanner">
                    <div className="bannerHead">
                        <div className="homeHead">Returning Soon....</div>
                        <div className="homeText">From 29 March, organised outdoor sports for both adults and children will also be able to resume with any numbers, where this takes place under NGB guidance. </div>

                        <div className="homeText">The rule of six for outdoor gatherings will return from this date and will include informal outdoor sport and recreation.</div>
                    </div>

                </div> */}
                <div className="homeText">Hockley Trail Runners is a friendly running club for runners of all abilities to enjoy local trails and off road routes.</div>
                <div className="homeText">The club is affiliated with UK Athletics &amp; offers qualified UK Athletics coaches and leaders to take sessions.</div>
                <div className="homeText">See our club page for details on running with us or becoming a member.</div>
                <div className="homeHead">#lovetrails</div>
            </div>
        </div>
    )
};

export default HomepageBody;