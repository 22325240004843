import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from './components/homepage';

function App() {

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Homepage path={null} />
        </Route>
        <Route path="/membership">
          <Homepage path="membership" />
        </Route>
        <Route path="/management">
          <Homepage path="management" />
        </Route>
        <Route path="/beginners">
          <Homepage path="beginners" />
        </Route>
        <Route path="/runs">
          <Homepage path="runs" />
        </Route>

        <Route path="/events">
          <Homepage path="events" />
        </Route>
        <Route exact path="/gallery">
          <Homepage path="gallery" />
        </Route>
        <Route exact path="/about">
          <Homepage path="about" />
        </Route>
        <Route exact path="/clubkit">
          <Homepage path="clubkit" />
        </Route>
        <Route exact path="/coaching">
          <Homepage path="coaching" />
        </Route>
        <Route exact path="/privacy">
          <Homepage path="privacy" />
        </Route>
        <Route exact path="/contact">
          <Homepage path="contact" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
