import React from 'react';
import Slide from 'react-reveal/Slide';

function Membership() {
	return (
		<div className="clubPage">
			<Slide right>
				<div className="pagebody">
					<div className="mainHead">Membership Options 2023-2024</div>
					<div className="pageText">Hockley Trail Runners is a fully affiliated club with UK athletics registration number 7692961.</div>
					<div className="pageText">Membership opens 1st March 2023 and runs from 1st April 2023 to 31st March 2024</div>
					<div className="pageText">
						<table border="1">
							<thead>
								<tr>
									<th></th>
									<th>Standard £10.00</th>
									<th>Affiliated £27.00</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Facebook Group</td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
								</tr>
								<tr>
									<td>Regular lead runs</td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
								</tr>
								<tr>
									<td>Race training groups</td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
								</tr>
								<tr>
									<td>Group Training Plans</td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
								</tr>
								<tr>
									<td>Running Workshops</td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
								</tr>
								<tr>
									<td>Cross Country League</td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
								</tr>
								<tr>
									<td>Coaching Support</td>
									<td></td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
								</tr>
								<tr>
									<td>Bespoke Training Plans</td>
									<td></td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
								</tr>
								<tr>
									<td>UK Athletics affiliation</td>
									<td></td>
									<td><span role="img" aria-label="greenheart">&#128154;</span></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="pageText">If you are already affiliated with another club but wish to run with us and enjoy additional benefits such as race training groups, plans and workshops, the standard membership is ideal.</div>
					<div className="pageText">If you aren't affiliated then the affiliated memberships offers a great package to help with your running.</div>
					<div className="pageText">All affiliated runners are registered with England Athletics myAthletics portal.</div>
					<div className="pageText">Registration is now online. Click the button below to register. (opens in new window)</div>
					<div className="pageText"><div className="greenBtn" onClick={() => window.open('https://www.sientries.co.uk/event_series.php?series_id=326')}>REGISTER HERE FOR 2023-2024</div></div>

					<div className="greenHead">Transfer affiliation</div>
					<div className="pageText">If you enjoy running with us more than your current affiliated club or perhaps haven't renewed a previous club membership for a while then you can transfer to us.  Full details to do this is in the document below.  You're welcome to try runs out with us for free and please don't hesitate to contact us about any queries.</div>
					<div className="pageText"><div className="greenBtn" onClick={() => window.open('https://storage.googleapis.com/wzukusers/user-26641997/documents/5aac0da842be9CAzCXkK/Moving%20from%20another%20affiliated%20club%20to%20Hockley%20Trail%20Runners.pdf')}>DOWNLOAD TRANSFER GUIDE</div></div>

					<div className="greenHead">Data protection</div>
					<div className="pageText">When you become a member or renew your membership with Hockley Trail Runners your details will be stored with SI Entries (our payment system partner).  Details of what is held can be found at <a href="https://www.sientries.co.uk/privacy.php">https://www.sientries.co.uk/privacy.php</a>.</div>
					<div className="pageText">Affiliated members will also  be registered as a member of England Athletics. We will provide England Athletics with your personal data which they will use to enable access to an online portal for you (called myAthletics). England Athletics will contact you to invite you to sign in to and update your MyAthletics portal (which, amongst other things, allows you to set and amend your privacy settings). If you have any questions about the continuing privacy of your personal data when it is shared with England Athletics, please contact <a href="mailto:dataprotection@englandathletics.org">dataprotection@englandathletics.org</a>.</div>
					<div className="pageText">The myAthletics portal is available as <a href="myathletics.englandathletics.org/portal/Members/Summary">myathletics.englandathletics .org/portal/Members/Summary</a></div>
					<div className="greenHead">Questions?</div>
					<div className="pageText">Any questions about membership or running with us please don't hesitate to contact us</div>
					<div className="greenHead">Privacy Policy</div>
					<div className="pageText">See our privacy policy to understand what data we collect, how we will use it and who we will share it with.</div>
					<div className="pageText"><div className="greenBtn" onClick={() => window.open('http://www.hockleytrailrunners.co.uk/privacy','_self')}>PRIVACY POLICY</div></div>
					<div className="pageText"></div>
				</div>
			</Slide>
		</div>
	);
};
export default Membership;