import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function About() {
  return (
    <div className="clubPage">
      <div className="pagebody">
        <div className="mainHead">About HTR</div>
        <div className="twoColText">
          <div className="twoColPageText">
            <div className="pageText">
              Hockley Trail Runners was formed in 2016. Our aim is to explore
              local trails in a safe and supportive way.
            </div>
            <div className="pageText">
              We are a mixed ability group, providing runs each week to cover
              the requirements of everyone.
            </div>
            <div className="pageText">
              We also provide dedicated training groups to support members
              training for local and national events, such as:
              <ul>
                <li>The Benfleet15</li>
                <li>Grizzly 20 (Devon)</li>
                <li>London Marathon</li>
                <li>Stort 30</li>
                <li>As well as an annual 'Couch to 5k' course to get absolute beginners into trail running</li>
              </ul>
            </div>
            <div className="pageText">
              We only have two rules:
              <ol>
                <li>Keep it about running.</li>
                <li>Have fun</li>
              </ol>
            </div>
          </div>
          <div className="twoColPageText">
            <Carousel autoPlay>
              <div>
                <img
                  alt=""
                  src="images/362662534_10229244524445209_4654341384739792214_n.jpg"
                />
                <p className="legend">Kirste 5</p>
              </div>
              <div>
                <img
                  alt=""
                  src="images/329607197_1706077889835446_7369728890953035316_n.jpg"
                />
                <p className="legend">Grizzly Finish Line</p>
              </div>
              <div>
                <img
                  alt=""
                  src="images/330033364_605342220969414_3075575166396511292_n.jpg"
                />
                <p className="legend">Seaton, Devon (Grizzly)</p>
              </div>
              <div>
                <img
                  alt=""
                  src="images/330327568_228309319598455_6173734559676095964_n.jpg"
                />
                <p className="legend">Wet Shoes Selfie</p>
              </div>
              <div>
                <img
                  alt=""
                  src="images/336507278_603439028021762_245811235620505094_n.jpg"
                />
                <p className="legend">Hadleigh Legacy 10k</p>
              </div>
              <div>
                <img
                  alt=""
                  src="images/344863507_191076330432592_7024539320234724789_n.jpg"
                />
                <p className="legend">Muddy Shoes Selfie</p>
              </div>
              <div>
                <img
                  alt=""
                  src="images/347388349_1653069021784265_4635802116707292548_n.jpg"
                />
                <p className="legend">Rayleigh 10k</p>
              </div>
              <div>
                <img
                  alt=""
                  src="images/352375120_10162110210492985_3705226554104497734_n.jpg"
                />
                <p className="legend">Hockley Trails</p>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
