import React from 'react';
import './header.css';


function Header() {
    return (
        <div>
            <div className="headerContents">
                <div className="logo" onClick={() => window.location.href = '/'} title="Return to Homepage"></div>
            </div>
        </div>
    );
};

export default Header;