import React from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Clubkit () {
    return (
        <div className="clubPage">
            
            <div className="pagebody">
            <div className="mainHead"><span>T-shirts and club kit</span></div>
            <div className="twoColText">
                     <div className="twoColPageText" height="400px">
                         <Carousel autoPlay>
                             <div>
                             <img alt="" src="images/hockley-trail-runners_d200.jpeg" />
                             <p className="legend">Runners T-Shirt</p>
                             </div>
                             <div>
                             <img alt="" src="images/hockley-trail-mens-summer-cool.jpg" />
                             <p className="legend">Summer Cool</p>
                             </div>
                             <div>
                             <img alt="" src="images/hockley-zip-front-mens.jpg" />
                             <p className="legend">Men Zip Front</p>
                             </div>
                             <div>
                             <img alt="" src="images/hockley-trail-runners-mens-vest.jpg" />
                             <p className="legend">Mens Vest</p>
                             </div>
                             <div>
                             <img alt="" src="images/hockley-trail-long-sleeve-back-mens.jpg" />
                             <p className="legend">Mens Long Sleeve</p>
                             </div>
                             <div>
                             <img alt="" src="images/hockley-trail-runners-zoodie-1.jpg" />
                             <p className="legend">Zoodie</p>
                             </div>
                             <div>
                             <img alt="" src="images/hockley-trail-runners-sweatshirt-quarter-zip.jpg" />
                             <p className="legend">Sweatshop !/4 Zip</p>
                             </div>
                             <div>
                             <img alt="" src="images/hockley-trail-runners-hoodie-2.jpg" />
                             <p className="legend">Runners Hoodie</p>
                             </div>
                         </Carousel>
                     </div>
                     <div className="twoColPageText">
                     <div className="pageText">We offer a wide range of t-shirts, summer cool t-shirts, hoodies, zoodies, vests, long sleeve t-shirts, performance tops, hats and snoods/buffs. Most of these are available in a wide range of sizes including mens, ladies and junior styles. These are ordered direct from our online shop with very quick delivery.</div>
                     <div className="pageText">Most can have your name or initials printed free of charge when you order.</div>
                     <div className="pageText">Hats and buffs are special order items and we do a regular order, making them available directly from the club.</div>
                     <div className="pageText">
                             <div className="greenBtn" onClick={()=>window.open('https://justalittlebit.co.uk/hockley-trail-runners-shop')}>ORDER YOUR KIT FROM HERE</div>
                     </div>
                     <div className="pageText"></div>
                     <div className="pageText"></div>
                     <div className="pageText"></div>
                     <div className="pageText"></div>
                     <div className="pageText"></div>
                     </div>
                 </div>
             </div>
             
        </div>
     );
}

export default Clubkit