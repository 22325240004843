import React, { useState, useEffect } from 'react';
import './menu.css';

function Menu() {
  const [mobileMenuOn, setMobileMenuOn] = useState(false);
  const [subMenuOn, setSubMenuOn] = useState(false);

  // const [runMenuOn, setRunMenuOn] = useState(false);
  const [page, setPage] = useState(null)

  useEffect(() => {
    const mobileBtns = document.getElementsByClassName('buttons')[0];
    if (page) { window.location.href = '/' + page; }
    if (mobileMenuOn) {
      mobileBtns.style.display === "block" && !subMenuOn ?
        mobileBtns.style.display = "none"
        : mobileBtns.style.display = "block"
    }
  })
  return (
    <div id="menu">
      <div className="phoneBtn" onClick={() => setMobileMenuOn(true)}>&#x2014;<br />&#x2014;<br />&#x2014;</div>
      <div className="buttons">
      <div className="mainBtn" onClick={() => window.location.href = '/'}>
          <div className="menuName">Home</div>
        </div>
        <div className="mainBtn" onClick={() => setPage("membership")}>
          <div className="menuName">Membership</div>
        </div>
        <div className="mainBtn" onClick={() => setSubMenuOn(true)}>
          <div className="menuName">Club</div>
          {subMenuOn ?
            <div className="subMenu">
              <div className="subBtn">
                <div className="submenuName" onClick={() => setPage("about")}>
                  <span className="spanRangeName">About Us</span>
                </div>
              </div>
              <div className="subBtn">
                <div className="submenuName" onClick={() => window.open('https://www.facebook.com/hockleytrailrunners')}>
                  <span className="spanRangeName">News</span>
                </div>
              </div>
              <div className="subBtn">
                <div className="submenuName" onClick={() => setPage("clubkit")}>
                  <span className="spanRangeName">Club Kit</span>
                </div>
              </div>
              <div className="subBtn">
                <div className="submenuName" onClick={() => setPage("privacy")}>
                  <span className="spanRangeName">Privacy</span>
                </div>
              </div>
            </div>

            : null
          }
        </div>
        <div className="mainBtn" onClick={() => setPage("runs")}>
          <div className="menuName">Runs</div>
        </div>
        {/* <div className="mainBtn" onClick={() => setRunMenuOn(true)}>
          <div className="menuName">Runs</div>
          {runMenuOn ?
            <div className="subMenu">
              <div className="subBtn">
                <div className="submenuName" onClick={() => setPage("runs")}>
                  <span className="spanRangeName">Training Runs</span>
                </div>
              </div>
              <div className="subBtn">
                <div className="submenuName" onClick={() => setPage("events")}>
                  <span className="spanRangeName">Upcoming Events</span>
                </div>
              </div>
            </div>

            : null
          }
        </div> */}
        <div className="mainBtn" onClick={() => setPage("gallery")}>
          <div className="menuName">Gallery</div>
        </div>
        <div className="mainBtn" onClick={() => setPage("beginners")}>
          <div className="menuName">Beginners</div>
        </div>
        <div className="mainBtn" onClick={() => setPage("contact")}>
          <div className="menuName">Contact&#160;Us</div>
        </div>
      </div>
    </div>
  )
};

export default Menu;
