import React, { useState } from 'react';
import axios from 'axios';
import './contact.css';

function Contact() {

  let [name, setName] = useState('');
  let [email, setEmail] = useState('');
  let [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    console.log(name, email, message)
    e.preventDefault();
    axios({
      method: "POST",
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: "https://www.hockleytrailrunners.co.uk/emailer/index.php",
      data: { name, email, message }
    }).then((response) => {
      if (response.data.status === 'success') {
        alert("Message Sent.");
        setEmail = '';
        setName = '';
        setMessage = '';
        window.location.href = '/';
      } else if (response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  }

  return (
    <div className="clubPage">
      <div className="pagebody">
        <div className="mainHead">Contact Us</div>
        <div className="greenHead">GET IN TOUCH WITH US!</div>
        <form id="contact-form" onSubmit={handleSubmit} method="POST">
          <div className="form-group">
            <input type="text" autoComplete="name" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
            <label htmlFor="name" className="inputLabel">Name</label>
          </div>
          <div className="form-group">
            <input type="text" autoComplete="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <label htmlFor="email" className="inputLabel">Email address</label>
          </div>
          <div className="form-group-message">
            <label htmlFor="message" className="textAreaLabel">Message</label>
            <textarea id="inputMessage" className="form-control" rows="5" value={message} onChange={(e) => setMessage(e.target.value)} />
          </div>
          <button type="submit" className="btn btn-primary">SEND MESSAGE</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;