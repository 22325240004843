import React from 'react';
function Runs() {
    return (
        <div className="runPage">
            <div className="pagebody">
                <div className="mainHead">Our runs</div>
                <div className="pageText">Our regular club runs are:</div>
                <div className="pageText"><ul>
                    <li>Tuesday Morning 7:30am start - Effort Sessions</li>
                    <li>Wednesday night 7:15pm for 7:30pm start - Group trail run. 3-4 groups of (3-4 miles, 4-5 miles and 6 miles)</li>
                    <li>Sunday morning 8:45am for 9:00am start - Group trail run.</li>
                </ul></div>
                <div className="pageText">The coaches and leaders often have additional runs available to fit in with race preparation and this is the current schedule.</div>
                <div className="pageText">
                    <table border="1">
                        <thead>
                            <tr >
                                <th>Day</th>
                                <th>Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>Monday</b></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><b>Tuesday</b></td>
                                <td>Effort Sessions</td>
                            </tr>
                            <tr>
                                <td><b>Wednesday</b></td>
                                <td><b>Club run (PM)</b><br />Group Trail run, 3-4 Groups</td>
                            </tr>
                            <tr>
                                <td><b>Thursday</b></td>
                                <td><b>Beginners group (PM)</b><br />When beginners group is running.</td>
                            </tr>
                            <tr>
                                <td><b>Friday</b></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><b>Saturday</b></td>
                                <td><b>Parkrun 9:00am</b><br />Free 5k run held at Hockley Woods, Hadleigh, Southend and Basildon</td>
                            </tr>
                            <tr>
                                <td><b>Sunday</b></td>
                                <td><b>Club run (AM)</b><br />Group Trail run</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="greenHead">Where do we meet?</div>
                <div className="pageText">Our runs meet at different locations each week so that we get to run on different trails and places.</div>
                <div className="pageText">Full details of the upcoming run is posted on our facebook group on the pinned post. For security reasons we don't make locations publicly available on the Internet.</div>
                <div className="pageText"><div className="greenBtn" onClick={() => window.open('https://www.facebook.com/groups/HockleyTrailRunners/')}>FACEBOOK GROUP</div></div>
                <div className="greenHead">How far and fast do we run?</div>
                <div className="pageText">We are a very responsive group and cater for all abilities. Our regular Wednesday run has the following groups:</div>
                <div className="pageText">
                    <ul>
                        <li>6 mile group</li>
                        <li>5 mile group</li>
                        <li>4 mile group</li>
                        <li>3 mile group</li>
                    </ul>
                </div>
                <div className="pageText">Generally groups run according to the group pace and regroup so we don't leave anyone behind.</div>
                <div className="pageText">Quite often runners may want to run a longer distance but at a different pace. Speak to the leaders about a top-up.</div>
                <div className="pageText">Each group is lead by one or more of our UK Athletics qualified leaders and coaches. </div>
                <div className="greenHead">What do I need to run with you?</div>
                <div className="pageText"><i>You will need to join as a member either standard or affiliated member.</i></div>
                <div className="pageText">We require that you fill in a membership form if you like running with us and want to join more regularly.</div>
                <div className="pageText">During the Winter months we continue to run trails at night time so a head torch is required.  We normally have a couple of spare torches to loan out if you aren't sure if you'll like it ( we think you will ).  During muddy/wet days trail shoes are recommended.</div>
                <div className="pageText">When a run is announced on facebook we indicate what is required e.g. head torch, trail shoes etc.</div>
                <div className="pageText">Ensure you have adequate running clothing to deal with changing weather throughout the seasons.</div>
                <div className="pageText">If you are unsure at all, please contact us or post up on our facebook group. You'll get very helpful responses.</div>
                <div className="greenHead">How are groups lead?</div>
                <div className="pageText">Groups are lead by UK Athletics qualified leaders and coaches. Most are also first aid qualified. Leaders and coaches are DBS checked and insured as part of their licence.</div>
                <div className="pageText">A typical group run begins with a briefing by the run director. Groups and paces are identified.</div>
                <div className="pageText">A warm up preceeds the run and the group leader will cover any specific information about the route.</div>
                <div className="pageText">The run begins.</div>
                <div className="pageText">A cool down stretch off is offered at the end of the run.</div>
                <div className="pageText">Share chat with other runners!</div>
                <div className="greenHead">How do I get a t-shirt?</div>
                <div className="twoColText">
                    <div className="twoColPageText">
                        <div className="pageText">We have club t-shirts available from our online shop. There are also hoodies, zoodies, various styles of t-shirt for winter and summer. All available in mens, ladies and junior fit. To help you get to know others, there is the option to have your name or initials on free of charge.</div>
                        <div className="pageText"><div className="greenBtn" onClick={() => window.open('https://justalittlebit.co.uk/hockley-trail-runners-shop','_self')}>CLUB KIT HERE</div></div>
                    </div>
                    <div className="twoColPageText"><img src="images/hockley-trail-runners_d200.jpeg" width="380px" alt="T-Shirt" /></div>
                </div>

                <div className="greenHead">Can my child run with us?</div>
                <div className="pageText">Children are allowed to run and are insured from 12 years old. The registration form would need to be signed by the parent. We also ask that the child runs with their parent.</div>
                <div className="pageText">&nbsp;</div>
            </div>
            
        </div>
    );
};

export default Runs;


