import React from 'react';

function Privacy() {
    return (
        <div className="clubPage">
            <div className="pagebody">
                <div className="mainHead"><span>Privacy Policy</span></div>
                <div className="greenHead">This page outlines how Hockley Trail Runners will use the data you provide when you register membership with us or renew your membership.</div>
                <div className="pageText">When you become a member or renew your membership with Hockley Trail Runners you will automatically be registered as a member of England Athletics. We will provide England Athletics with your personal data which they will use to enable access to an online portal for you (called myAthletics). England Athletics will contact you to invite you to sign in to and update your MyAthletics portal (which, amongst other things, allows you to set and amend your privacy settings). If you have any questions about the continuing privacy of your personal data when it is shared with England Athletics, please contact dataprotection@englandathletics.org.</div>
                <div className="pageText">The myAthletics portal is available as myathletics.englandath letics.org/portal/Members/Summary</div>
                <div className="greenHead">what information do we collect and how do we use it</div>
                <div className="pageText"><b>The information we collect when you register with us is as follows:</b></div>
                <div className="pageText">
                    <ul>
                        <li>Name, age, address and contact number</li>
                        <li>Next of kin details including name, address and contact number</li>
                        <li>any medical conditions that may affect your running or we need to be aware of whilst running</li>
                        <li>UK Athletics affiliation number (if you have one)</li>
                        <li>your running/fitness goals</li>
                        <li>basic running history (how long, how far, experience)</li>
                    </ul>
                </div>
                <div className="pageText">How we use the information provided to us</div>
                <div className="pageText">We may use your personal information for a number of purposes, including:</div>
                <div className="pageText">
                    <ul>
                        <li>Register you with england athletics using their myathletics portal</li>
                        <li>Deal with enquiries and requests</li>
                        <li>Processing of membership forms and payments</li>
                        <li>Share data with coaches and leaders of the club to administer training sessions</li>
                        <li>Share data with club coordinators to enter running and social events</li>
                        <li>Share data with leagues, county associations and other competition providers for entry in events</li>
                        <li>Share information with core team members to provide information about club activities, membership renewals, or invitation to social events</li>
                        <li>Publishing of race and competition results</li>
                        <li>Website management</li>
                        <li>Sending information about promotions</li>
                        <li>Sending official club correspondence</li>
                        <li>Sending information about selling club kit, merchandise or fundraising</li>
                    </ul>
                </div>
                <div className="pageText">Protecting your information</div>
                <div className="pageText">The data that we collect from you will be transferred to England Athletics myAthletics portal.</div>
                <div className="pageText">By submitting your personal data, you agree to this transfer, storing or processing. We will take all reasonable steps to ensure that your data is treated securely and in accordance with this privacy policy. The Internet is not generally a secure medium for communication and therefore we cannot guarantee the security of any information you send to us over the Internet. We use up - to - date industry procedures to protect your personal information. We also protect the security of your data using Secure Sockets Layer (SSL) encryption technology.</div>
                <div className="pageText">Finding out what information we hold about you</div>
                <div className="pageText">Under the Data Protection Legislation, you can ask to see any personal information that we hold about you. Such requests are called subject access requests. If you would like to make a subject access request, please contact the Club secretary or membership secretary</div>
                <div className="pageText">You will also need to provide two forms of identification, for example, driving licence, utility bill or passport and, if appropriate, any particulars about the source or location of the information you are requesting.</div>
                <div className="pageText">Data retention</div>
                <div className="pageText">Any data that we collect from you will be deleted in accordance with timescales set out below:</div>
                <div className="pageText">Four years after an individual has not played an active part in the sport.</div>
                <div className="pageText">What are cookies? The types of cookies we use and how you can disable them.</div>
                <div className="pageText">In this section you will find information on what cookies may be set when you visit the Hockley Trail Runners website and how to manage and disable them.</div>
                <div className="pageText">What are cookies?</div>
                <div className="pageText">A cookie is a piece of text that a website transfers to your computer’s hard disk in order to remember who you are. Cookies interact with your computer and our website faster and easier by remembering the registration details and preferences from previous visits. Typically, a cookie will contain the name of the website from which the cookie has come, the lifetime of the cookie and a value. The use of cookies does not give us access to the rest of your computer and only the site that created the cookie can read it.</div>
                <div className="pageText">Automatically collected access information</div>
                <div className="pageText">Essential technical information: identification of the page or service you are requesting, type of browser and operating system you are using; and the date and time of access.</div>
                <div className="pageText">Nonessential technical information: the Internet address of the web site from which you linked directly to our web site, and the "cookie information" described below. By continuing to use this site you consent to the relevant cookies being set on your device.</div>
                <div className="pageText">We use the following types of cookies</div>
                <div className="pageText">
                    <ol>
                        <li>Session cookies – these are temporary cookies that remain in the internet browser’s folder of your computer until you close the browser. Our website uses a session cookie which is stored in your browser until you close it to ensure connections to our servers are distributed evenly to provide optimal performance.</li>
                        <li>Persistent/Permanent cookies – these remain in the internet browser’s folder of your computer for longer than the time you are visiting this website. We need to use a cookie to remember your refusal of our use of cookies. This cookie does not store any other information and is not used for any other purposes. If you delete your browsing history including cookies you will delete this cookie and will therefore need to readjust your browser setting again.</li>
                        <li>Analytical cookies – Our website uses Google Analytics cookies (third party cookie) for the following purposes: a cookie to record the time of your first visit to website, the time of your most recent visit to the website and th e time of your current visit; a cookie to record how long you stayed on the website; a cookie to record how you located our website (i.e. Google search, keyword, link from other page etc).</li>
                    </ol>
                </div>
                <div className="pageText">The cookies we use for these purposes do not track your Internet usage after leaving our website and we do not store your personal information. They will also not be used in connection with any other information to identify you in any way.</div>
                <div className="pageText">To prevent Google Analytics cookies being set, you may wish to install the Google Analytics Opt - Out Browser Add - On (http://tools.google.com/dlpage/gaoptout).</div>
                <div className="pageText"></div>
            </div>
        </div>
    );
}

export default Privacy;
