import React from 'react';
import Header from './header';
import HomepageBody from './homepageBody';
import Beginners from './MainPages/beginners';
import Membership from './MainPages/membership';

import Runs from './MainPages/runs';
import Events from './MainPages/events';
import Gallery from './MainPages/gallery';
import About from './ClubPages/About';
import Clubkit from './ClubPages/clubkit';
import Privacy from './ClubPages/privacy';
import Contact from './Contact/contact';
import Menu from './Menu/menu';

import './homepage.css';

function Homepage(props) {
	const pagebody = props.path === "membership" ?
		<Membership />
		: props.path === "beginners" ?
			<Beginners />
			: props.path === "runs" ?
				<Runs />
				: props.path === "gallery" ?
					<div><HomepageBody /><Gallery /></div>
					: props.path === "about" ?
						<About />
						: props.path === "clubkit" ?
							<Clubkit />
							: props.path === "privacy" ?
								<Privacy />
								: props.path === "contact" ?
									<Contact />
									: props.path === "events" ?
									<Events />
									: <HomepageBody />

	return (
		<div>
			<div id="header">
				<Header />
				<Menu page={props.path} />
			</div>
			<div id="body">
				{pagebody}
				<div className="footer">All rights reserved © Hockley Trail Runners 2024</div>
			</div>
		</div>
	);
}

export default Homepage;
